import { appendName } from "../utils";
import { BORROWER_TYPES } from "@/store/borrower/constants";

const append = appendName("REPORTS");

export const actions = {
  GET_REPORT: append("GET_REPORT"),
  GET_AP_AGING_DETAIL_DATE_OPTIONS: append("GET_AP_AGING_DETAIL_DATE_OPTIONS"),
  GET_AR_AGING_DETAIL_DATE_OPTIONS: append("GET_AR_AGING_DETAIL_DATE_OPTIONS"),
  GET_CLIENT_LOAN_STATUS_BUSINESS_STATUS: append("GET_CLIENT_LOAN_STATUS_BUSINESS_STATUS"),
  GET_FTA_INFO: append("GET_FTA_INFO"),
  GET_HC_TICKLER_FIELDS: append("GET_HC_TICKLER_FIELDS"),
  GET_CSR3_INFORMATION: append("GET_CSR3_INFORMATION"),
  GET_DAILY_LOAN_INFORMATION: append("GET_DAILY_LOAN_INFORMATION"),
  GET_HEALTHCARE_DAILY_LOAN_INFORMATION: append("GET_HEALTHCARE_DAILY_LOAN_INFORMATION"),
  GET_INELIGIBLES_DATE_OPTIONS: append("GET_INELIGIBLES_DATE_OPTIONS"),
  GET_TREND_CARD_COMMENTARY: append("GET_TREND_CARD_COMMENTARY"),
  CLEAR_REPORT: append("CLEAR_REPORT")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_REPORT: append("SET_REPORT"),
  SET_REPORT_DISABLED: append("SET_REPORT_DISABLED"),
  SET_REPORT_CRITERIA: append("SET_REPORT_CRITERIA"),
  UPDATE_REPORT_CRITERIA: append("UPDATE_REPORT_CRITERIA"),
  SET_REPORT_CRITERIA_CHANGED: append("SET_REPORT_CRITERIA_CHANGED"),
  SET_SELECTED_REPORT_NAME: append("SET_SELECTED_REPORT_NAME")
};

// This is set to what the back-end expects as the report name in the PATH
export const REPORTS = {
  LOAN_LEDGER: "LOAN_LEDGER",
  AP_AGING_DETAIL: "AP_AGING_DETAIL",
  MONTHLY_INTEREST_STATEMENT: "MONTHLY_INTEREST_STATEMENT",
  INELIGIBLES: "INELIGIBLES",
  CLIENT_LOAN_STATUS: "CLIENT_LOAN_STATUS",
  FTA: "FTA",
  CSR2: "CSR2",
  CSR3: "CSR3",
  AVAILABILITY: "AVAILABILITY",
  GENERAL_LEDGER: "GENERAL_LEDGER",
  ADVANCE: "ADVANCE",
  DAILY_LOAN: "DAILY_LOAN",
  HEALTHCARE_DAILY_LOAN: "HEALTHCARE_DAILY_LOAN",
  AR_AGING_DETAIL: "AR_AGING_DETAIL",
  AR_AGING_SUMMARY: "AR_AGING_SUMMARY",
  PARTICIPATION_SETTLEMENT: "PARTICIPATION_SETTLEMENT",
  HCTICKLER: "HCTICKLER",
  MODELTREND: "MODELTREND",
  HCTICKLER: "HCTICKLER",
  PORTFOLIO_DASHBOARD: "PORTFOLIO_DASHBOARD",
  SHIFT_ANALYSIS: "SHIFT_ANALYSIS",
  PORTFOLIO_TICKLER: "PORTFOLIO_TICKLER",
  MANAGEMENT: "MANAGEMENT",
  MANAGEMENT_CEO: "MANAGEMENT_CEO",
  LOANTAPE: "LOAN_TAPE",
  INTEREST_AND_PROOF: "INTEREST_AND_PROOF",
  PORTFOLIO_REVIEW: "PORTFOLIO_REVIEW",
  DEBTOR_CONCENTRATION: "DEBTOR_CONCENTRATION",
  HEALTHCARE_CENSUS: "HEALTHCARE_CENSUS",
  PAYOR_CLASS_SUMMARY: "PAYOR_CLASS_SUMMARY"
};

export const REPORT_ACTIONS = {
  VIEW: "VIEW",
  DOWNLOAD_PDF: "DOWNLOAD_PDF",
  DOWNLOAD_XLSX: "DOWNLOAD_XLSX",
  DOWNLOAD_CSV: "DOWNLOAD_CSV",
  DOWNLOAD_SAGE: "DOWNLOAD_SAGE"
};

export const BORROWER_SEGMENTS = {
  CURRENT: "current",
  ALL: "all"
};

export const BORROWER_SEGMENT_OPTIONS = [
  { value: BORROWER_SEGMENTS.CURRENT, label: "Current Borrower" },
  { value: BORROWER_SEGMENTS.ALL, label: "All Borrowers" }
];

export const FORMAT_TYPES = {
  HTML: "html",
  PDF: "pdf",
  XLSX: "xlsx",
  CSV: "csv"
};

export const DEFAULT_REPORT_CRITERIA = {
  name: null,
  type: FORMAT_TYPES.PDF
};

export const USER_PREFIX = "user__";

/*
  Options:
    name:           required get from "REPORTS" const above)
    title:          required
    icon:           required font awesome styles - e.g. fas fa-book fa-lg
    exportTypes:    required array - e.g. [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX] (possible values: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX, FORMAT_TYPES.CSV])
    submitLabel:    optional default: Preview
    buttonPosition: optional default: "mb-auto"
    canPreview:     optional true/false, default: true
    autoPreview:    optional default: false (only set to true with reports with no criteria)
    sienaOnlyUsers: optional default: false (only set to true if only visible to Siena Users)
    permission:     required get from available permissions
    borrowerTypes:  optional default: all (array of borrower types report is available for; e.g., [BORROWER_TYPES.SIENA, BORROWER_TYPES.CANADA])
*/
export const REPORTS_DEFINITION = {
  [REPORTS.LOAN_LEDGER]: {
    name: REPORTS.LOAN_LEDGER,
    title: "Loan Ledger",
    icon: "fas fa-book fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "loan_ledger"
  },
  [REPORTS.AP_AGING_DETAIL]: {
    name: REPORTS.AP_AGING_DETAIL,
    title: "AP Aging Detail",
    icon: "fa-solid fa-magnifying-glass-dollar fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "a_p_aging_detail"
  },
  [REPORTS.MONTHLY_INTEREST_STATEMENT]: {
    name: REPORTS.MONTHLY_INTEREST_STATEMENT,
    title: "Monthly Interest Stmt",
    icon: "fas fa-calendar fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "monthly_interest_stmt"
  },
  [REPORTS.INELIGIBLES]: {
    name: REPORTS.INELIGIBLES,
    title: "Ineligibles",
    icon: "fa-solid fa-sack-xmark fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "ineligibles"
  },
  [REPORTS.CLIENT_LOAN_STATUS]: {
    name: REPORTS.CLIENT_LOAN_STATUS,
    title: "Loan Status",
    icon: "fas fa-university fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    submitLabel: "Save & Preview",
    buttonPosition: "mt-auto",
    permission: "loan_status"
  },
  [REPORTS.FTA]: {
    name: REPORTS.FTA,
    title: "Financials",
    icon: "fas fa-code fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    submitLabel: "Save & Preview",
    buttonPosition: "mt-auto",
    permission: "fta"
  },
  [REPORTS.CSR2]: {
    name: REPORTS.CSR2,
    title: "Appraisal & Field Exam",
    icon: "fa-solid fa-check-to-slot fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    autoPreview: true,
    permission: "appraisal_field_exam"
  },
  [REPORTS.CSR3]: {
    name: REPORTS.CSR3,
    title: "Trends",
    icon: "fa-solid fa-money-bill-trend-up fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    submitLabel: "Save & Preview",
    buttonPosition: "mt-auto",
    permission: "fta"
  },
  [REPORTS.AVAILABILITY]: {
    name: REPORTS.AVAILABILITY,
    title: "Availability",
    icon: "fas fa-balance-scale fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    permission: "availability_report"
  },
  [REPORTS.GENERAL_LEDGER]: {
    name: REPORTS.GENERAL_LEDGER,
    title: "General Ledger",
    icon: "fas fa-book fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.CSV],
    sienaOnlyUsers: true,
    permission: "general_ledger"
  },
  [REPORTS.DAILY_LOAN]: {
    name: REPORTS.DAILY_LOAN,
    title: "Daily Loan",
    icon: "fas fa-credit-card fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    submitLabel: "Save & Preview",
    buttonPosition: "mt-auto",
    sienaOnlyUsers: true,
    permission: "daily_loan",
    borrowerTypes: [BORROWER_TYPES.SIENA, BORROWER_TYPES.CANADA]
  },
  [REPORTS.HEALTHCARE_DAILY_LOAN]: {
    name: REPORTS.HEALTHCARE_DAILY_LOAN,
    title: "Daily Loan",
    icon: "fas fa-credit-card fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    submitLabel: "Save & Preview",
    buttonPosition: "mt-auto",
    sienaOnlyUsers: true,
    permission: "daily_loan",
    borrowerTypes: [BORROWER_TYPES.HEALTHCARE]
  },
  [REPORTS.AR_AGING_DETAIL]: {
    name: REPORTS.AR_AGING_DETAIL,
    title: "AR Aging Detail",
    icon: "fa-solid fa-magnifying-glass-dollar fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "a_r_aging_detail"
  },
  [REPORTS.AR_AGING_SUMMARY]: {
    name: REPORTS.AR_AGING_SUMMARY,
    title: "AR Aging Summary",
    icon: "fa-solid fa-magnifying-glass-dollar fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "a_r_aging_summary"
  },
  [REPORTS.PARTICIPATION_SETTLEMENT]: {
    name: REPORTS.PARTICIPATION_SETTLEMENT,
    title: "Participation Settlement",
    icon: "fa-solid fa-magnifying-glass-dollar fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    permission: "participation_settlement"
  },
  [REPORTS.HCTICKLER]: {
    name: REPORTS.HCTICKLER,
    title: "Portfolio Tracker",
    icon: "fas fa-clock fa-lg",
    canPreview: false,
    exportTypes: [FORMAT_TYPES.XLSX],
    buttonPosition: "mt-auto",
    permission: "hc_tickler"
  },
  [REPORTS.MODELTREND]: {
    name: REPORTS.MODELTREND,
    title: "Trend Card",
    icon: "fas fa-poll-h fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "trend_card"
  },
  [REPORTS.PORTFOLIO_DASHBOARD]: {
    name: REPORTS.PORTFOLIO_DASHBOARD,
    title: "Portfolio Dashboard",
    icon: "fas fa-clock fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    permission: "portfolio_dashboard"
  },
  [REPORTS.SHIFT_ANALYSIS]: {
    name: REPORTS.SHIFT_ANALYSIS,
    title: "Shift Analysis",
    icon: "fas fa-book fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    buttonPosition: "mt-auto",
    permission: "shift_analysis"
  },
  [REPORTS.PORTFOLIO_TICKLER]: {
    name: REPORTS.PORTFOLIO_TICKLER,
    title: "Portfolio Tickler",
    icon: "fas fa-clock fa-lg",
    canPreview: false,
    exportTypes: [FORMAT_TYPES.XLSX, FORMAT_TYPES.PDF],
    buttonPosition: "mt-auto",
    permission: "portfolio_dashboard"
  },
  [REPORTS.MANAGEMENT]: {
    name: REPORTS.MANAGEMENT,
    title: "Management",
    icon: "fas fa-columns fa-lg",
    exportTypes: [FORMAT_TYPES.XLSX, FORMAT_TYPES.PDF],
    permission: "management"
  },
  [REPORTS.MANAGEMENT_CEO]: {
    name: REPORTS.MANAGEMENT_CEO,
    title: "Management - CEO",
    icon: "fas fa-columns fa-lg",
    exportTypes: [FORMAT_TYPES.XLSX, FORMAT_TYPES.PDF],
    permission: "management_ceo"
  },
  [REPORTS.LOANTAPE]: {
    name: REPORTS.LOANTAPE,
    title: "Loan Tape",
    icon: "fas fa-columns fa-lg",
    canPreview: false,
    exportTypes: [FORMAT_TYPES.XLSX],
    permission: "loan_tape"
  },
  [REPORTS.INTEREST_AND_PROOF]: {
    name: REPORTS.INTEREST_AND_PROOF,
    title: "Interest and Proof",
    icon: "fa-solid fa-percent fa-lg",
    exportTypes: [FORMAT_TYPES.XLSX, FORMAT_TYPES.PDF],
    permission: "interest_and_proof"
  },
  [REPORTS.PORTFOLIO_REVIEW]: {
    name: REPORTS.PORTFOLIO_REVIEW,
    title: "Portfolio Review",
    icon: "fas fa-credit-card fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    sienaOnlyUsers: true,
    permission: "portfolio_review"
  },
  [REPORTS.DEBTOR_CONCENTRATION]: {
    name: REPORTS.DEBTOR_CONCENTRATION,
    title: "Debtor Concentration",
    icon: "fa-solid fa-scale-unbalanced fa-lg",
    exportTypes: [FORMAT_TYPES.PDF],
    permission: "debtor_concentration"
  },
  [REPORTS.HEALTHCARE_CENSUS]: {
    name: REPORTS.HEALTHCARE_CENSUS,
    title: "Census",
    icon: "fas fa-people-group fa-lg",
    canPreview: false,
    exportTypes: [FORMAT_TYPES.XLSX],
    sienaOnlyUsers: true,
    permission: "healthcare_census",
    borrowerTypes: [BORROWER_TYPES.HEALTHCARE]
  },
  [REPORTS.PAYOR_CLASS_SUMMARY]: {
    name: REPORTS.PAYOR_CLASS_SUMMARY,
    title: "Payor Class Summary",
    icon: "fas fa-magnifying-glass-dollar fa-lg",
    exportTypes: [FORMAT_TYPES.PDF, FORMAT_TYPES.XLSX],
    sienaOnlyUsers: true,
    permission: "payor_class_summary",
    borrowerTypes: [BORROWER_TYPES.HEALTHCARE]
  }
};

export const CONFIG = Object.values(REPORTS_DEFINITION);

export const LOAN_AND_COLLATERAL_REPORT_GROUP = [
  REPORTS_DEFINITION.AR_AGING_SUMMARY,
  REPORTS_DEFINITION.AR_AGING_DETAIL,
  REPORTS_DEFINITION.AP_AGING_DETAIL,
  REPORTS_DEFINITION.AVAILABILITY,
  REPORTS_DEFINITION.INELIGIBLES,
  REPORTS_DEFINITION.LOAN_LEDGER,
  REPORTS_DEFINITION.MONTHLY_INTEREST_STATEMENT
];
export const MONITORING_REPORT_GROUP = [
  REPORTS_DEFINITION.DAILY_LOAN,
  REPORTS_DEFINITION.MODELTREND,
  REPORTS_DEFINITION.PORTFOLIO_DASHBOARD,
  REPORTS_DEFINITION.PORTFOLIO_TICKLER,
  REPORTS_DEFINITION.SHIFT_ANALYSIS,
  REPORTS_DEFINITION.MANAGEMENT,
  REPORTS_DEFINITION.MANAGEMENT_CEO,
  REPORTS_DEFINITION.DEBTOR_CONCENTRATION
];
export const HEALTHCARE_REPORT_GROUP = [
  REPORTS_DEFINITION.HEALTHCARE_DAILY_LOAN,
  REPORTS_DEFINITION.HCTICKLER,
  REPORTS_DEFINITION.HEALTHCARE_CENSUS,
  REPORTS_DEFINITION.PAYOR_CLASS_SUMMARY
];
export const PORTFOLIO_REVIEW_GROUP = [
  REPORTS_DEFINITION.CLIENT_LOAN_STATUS,
  REPORTS_DEFINITION.CSR2,
  REPORTS_DEFINITION.CSR3,
  REPORTS_DEFINITION.FTA,
  REPORTS_DEFINITION.PORTFOLIO_REVIEW
];
export const FINANCE_REPORT_GROUP = [
  REPORTS_DEFINITION.GENERAL_LEDGER,
  REPORTS_DEFINITION.PARTICIPATION_SETTLEMENT,
  REPORTS_DEFINITION.MONTHLY_INTEREST_STATEMENT,
  REPORTS_DEFINITION.LOAN_TAPE,
  REPORTS_DEFINITION.INTEREST_AND_PROOF
];
